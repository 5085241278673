import { Pipes, RequestUtil } from '../../../utils';
import { ProductDto, ShopProductCreateDto, ShopProductUpdateDto } from './dto';
import { ShopProductMediaCreateDto } from './dto/shop-product-media-create.dto';

class ShopProductActions {

  static async save(data: ProductDto) {
    const url = '/products';
    if (data.uuid) {
      return RequestUtil.patch(
        `${url}/${data.uuid}`,
        Pipes.transform(ShopProductUpdateDto, data),
        {},
        () => ProductDto,
      );
    }
    return RequestUtil.post(
      url,
      Pipes.transform(ShopProductCreateDto, data),
      {},
      () => ProductDto,
    );
  }

  static async saveMedia(data: ShopProductMediaCreateDto) {
    const url = '/products';
    return RequestUtil.post(
      `${url}/${data.productUUID}/media`,
      Pipes.transform(ShopProductMediaCreateDto, {medias: data.medias}),
      {},
      () => ProductDto,
    );
  }
}

export { ShopProductActions };
